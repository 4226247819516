import { authenticateUser, getBalance, loginCheck } from '../api/seven';
import createUserFromRsg from './createUserFromRsg';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import types from '@/store/mutationTypes';

// eslint-disable-next-line no-unused-vars
let balanceTimeout = null;
const getUserBalance = async (data, token, store) => {
  const manualBalanceUpdate = store.getters.ui?.config?.manualBalanceUpdate || false;
  const manualBalanceTimeoutValue = store.getters.ui?.config?.manualBalanceTimeoutValue || 60000;
  const newToken = token || store.getters.queryParametars.get('token');
  if (!manualBalanceUpdate) return;
  const balance = await getBalance(data, newToken);
  store.dispatch('user/setUserBalance', balance);
  balanceTimeout = setTimeout(() => {
    getUserBalance(data, newToken, store);
  }, manualBalanceTimeoutValue);
};

const rgsInit = async (store) => {
  const data = {
    playerId: store.getters.queryParametars.get('playerId'),
    token: store.getters.queryParametars.get('token'),
    application: 'Web',
    locale: store.getters.queryParametars.get('locale'),
    tenantId: store.getters.config.tenantId,
  };
  const auth = await authenticateUser(data);
  store.dispatch('setOddType', 1);
  if (auth.status !== 200) return;
  store.dispatch('setTpToken', data.token);
  const userLoginCheck = await loginCheck(data, auth.headers['access-token']);
  if (userLoginCheck.status !== 200)  return;
  store.commit('SET_RGS_INTEGRATION', true);
  const userData = createUserFromRsg({
    token: auth.headers['access-token'],
    balance: 0,
    ...userLoginCheck.data,
  });
  store.commit(types.SET_USER_DATA, userData);
  store.commit('SET_TIME_ZONE', userData.timezone);
  store.dispatch('setUser', userData);
  store.dispatch('user/updateUser', {
    auth: {
      token: userData.token,
      tpToken: userData.token,
    },
    profile: {
      ...userData,
      logged: true,
    },
    isLoggedIn: true,
  });
  await getUserBalance(data, auth.headers['access-token'], store);
  store.dispatch('getLastTickets');
  if (store.getters.queryParametars.get('clientType') === 'token'
    && store.getters.queryParametars.get('token')) {
    EventBus.$on('updateBalance', () => {
      console.log('update balance', store.getters, auth.headers['access-token']);
      clearTimeout(balanceTimeout);
      getUserBalance(data, auth.headers['access-token'], store);
    });
  }
};

export default rgsInit;
