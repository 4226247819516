import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'floating-vue/dist/style.css';
import isMobile from 'ismobilejs';
import getParams from '@/utility/getParams';

store.state.isAndroid = isMobile().android.device;
store.commit('SET_PARAMS', getParams());
store.dispatch('setUserAgentDetails', isMobile());

window.addEventListener('message', (event) => {
  if (event.data?.action === 'Slave.Load') {
    store.state.currency = event.data.data.user.currency;
    store.state.showCursor = event.data.data?.settings?.showCursor;
    store.dispatch('handleSocketMessage', {
      eventName: event.data.action,
      data: event.data.data,
    });
  }
});
window.addEventListener('Bus:Message', (event) => {
  store.dispatch('handleSocketMessage', event.detail);
});
window.addEventListener('Configuration:SetConfiguration', (e) => {
  store.state.isIntegration = e.detail.isIntegration;
  store.state.moneyDenominations = e.detail.moneyDenominations;
  store.state.scrollButtons = e.detail.scrollButtons?.value;
  store.state.gatewayConfigurationDetails = e.detail;
  store.dispatch('gamesBetslip/setBetslipBlockers', {
    blockers: e.detail.betslip?.blockers || [],
    type: e.detail.betslip?.blockers.length ? 'add' : 'remove',
  })
});
if (store.getters.layoutTerminal) {
  window.addEventListener('offline', () => {
    store.dispatch('setInternetConnection', false);
  });
  window.addEventListener('online', () => {
    store.dispatch('setInternetConnection', true);
    // EventBus.$emit('getNewEvents');
  });
}

createApp(App).use(router).use(store).mount('#app');
