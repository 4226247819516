import { ConfigurationService } from '@nsftx/games-config';
import has from 'lodash/has';
import { defaultTo, last } from 'lodash';
import throwError from '@/utility/throwError';

const getProperSlaveIdName = (productName, isTerminal) => {
  let slaveId;
  const path = window.location.pathname;
  switch (path) {
    case '/ticket-details/':
    case '/backoffice-ticket-details/':
      slaveId = isTerminal ? `Plugin${productName}TicketDetails` : `Plugin${productName}`;
      break;
    case '/terminal-results/':
      slaveId = `Plugin${productName}Results`;
      break;
    case '/backoffice-tickets-list/':
      slaveId = `Plugin${productName}TicketsHistory`;
      break;
    default:
      slaveId = `Plugin${productName}`;
      break;
  }
  return slaveId;
};

const getConfigService = async (store) => {
  let config;
  try {
    /**
     * Instance of config service
     */
    const tenantId = defaultTo(store.getters.queryParametars.get('companyId'),
      store.getters.queryParametars.get('tenantId'));
    const clientPlatform = store.getters.queryParametars.get('clientPlatform');
    const application = store.getters.queryParametars.get('application');
    const allowDebug = tenantId === 'b99752b3-443c-4c80-b559-945a95c4b805'
      && !!store.getters.queryParametars.get('debug');
    let applicationName;
    if (store.getters.isTerminal) {
      applicationName = 'Terminal';
    } else {
      applicationName = clientPlatform || application || 'Web';
    }
    const configurationDataPrepare = {
      requiredPaths: [
        'productId',
        'tenantId',
        'locale',
      ],
      messageProtocol: 'gravityGateway',
      clientAdapters: [
        {
          name: 'GravityGatewayAdapter',
          options: {
            slaveId: getProperSlaveIdName(store.getters.queryParametars.get('productName'), store.getters.isTerminal),
            gameId: store.getters.queryParametars.get('productName'),
            isDebug: allowDebug,
            eventPropagation: {
              click: true,
              keydown: "*"
            },
          },
        },
      ],
      ui: tenantId,
      environment: process.env.VUE_APP_ENVIRONMENT === 'production' ? 'production' : 'staging',
      platformName: 'seven',
      productName: store.getters.queryParametars.get('productName'),
      applicationName,
    };
    const configService = await new ConfigurationService(configurationDataPrepare);
    /**
     * Get config
     */
    setTimeout(() => {
      if (store.getters.isAndroid && has(window, 'WebAppListener')) {
        // eslint-disable-next-line no-undef
        WebAppListener.sendMessage('Slave.Init');
      }
    }, 200);
    config = await configService.getConfiguration();
    store.dispatch('setCurrentEvent', last(config.state.gameState).eventName);
    if (!config) throwError('Please try later no config data');

  } catch (error) {
    console.log('error', error);
  }
  return config;
};

export default getConfigService;
