import types from './mutationTypes';
import {
  forEach,
  indexOf,
  map,
  replace,
  has,
  find,
  findIndex,
} from 'lodash';
import {getData, postData, getGameServiceData} from '@/api';
import EventBus from '@nsftx/games-sdk-js/src/utility/eventBus';
import {ticketFormater, formatPrintTicketBets} from '@/utility/ticketFormater';
import {createHeaders, createQueryParams} from '@/api/utility';

const sendMessageToVisualization = (payload) => {
  const visualizationElement = document.getElementById('visualization');
  if (visualizationElement) {
    visualizationElement.contentWindow.postMessage(payload, '*');
  }
};

export default {
  toggleInfoModal({ commit }) {
    commit(types.TOGGLE_INFO_MODAL);
  },
  setInternetConnection: ({ commit }, payload) => {
    commit(types.SET_INTERNET_CONNECTION, payload);
  },
  updateActiveEvent({ commit }, payload) {
    commit(types.UPDATE_ACTIVE_EVENT, payload);
  },
  setGamesInfoActive({ commit }, payload) {
    commit(types.SET_GAMES_INFO_ACTIVE, payload);
  },
  setIsVisualizationReady({ commit }, payload) {
    commit(types.SET_IS_VISUALIZATION_READY, payload);
  },
  setCurrentEvent({ commit }, payload) {
    commit(types.SET_CURRENT_EVENT, payload)
  },
  handleSocketMessage({ commit, dispatch, state, getters}, payload) {
    // Send relevant messages only if the visualisation is ready
    const messagesToBounce = ['startCountdown', 'showResults', 'showVideos', 'updateJackpot'];

    const shouldTriggerVideoAvailability = ({ eventName }) =>
    messagesToBounce.slice(0, -1).includes(eventName) && getters.isVisualizationReady

    if (shouldTriggerVideoAvailability(payload)) commit(types.SET_IS_VIDEO_UNAVAILABLE, false)
    if (!getters.layoutTerminal) {
      const shouldSendMessage = ({ eventName }) =>
        messagesToBounce.includes(eventName) && getters.isVisualizationReady

      if (shouldSendMessage(payload)) {
        if(payload.eventName !== 'updateJackpot')  dispatch('setCurrentEvent', payload.eventName);
        sendMessageToVisualization(payload);
      }
    }
    let timezone;
    switch (payload.eventName) {
      // First load message
      case 'Slave.Load':
        commit('SET_SEVEN_DATA', payload.data);
        if (payload.data.channel === 'Terminal') {
          dispatch('setTerminalUser', payload.data.user);
        }
        break;
      // game messages
      case 'stopBetting':
        dispatch('getOffer', payload);
      break;
      case 'showResults':
        EventBus.$emit('muteVideo');
        EventBus.$emit('updateResults');
        if (getters.queryParametars.get('clientType') === 'token'
          && getters.queryParametars.get('token') && getters['user/isLoggedIn']) {
          dispatch('getLastTickets');
        }
        break;
      case 'TicketUpdate': {
        const ticket = payload.data;
        if (payload.data.error && getters.isTerminal) {
          window.busService.sendMessage({
            action: 'Dialog.Show',
            data: {
              message: getters.translations[ticket.message],
              type: 'error',
              delay: 3000,
            },
          }, true);
        }
        if (!payload.data.error && getters.isTerminal) {
          EventBus.$emit('resetTerminalPayin', false);
          if (getters.terminalUser.type === 'ANONYMOUS' && ticket.action === 'Payout') return;
          dispatch('callTerminalPayin', ticket);
        }
        if (!getters.layoutTerminal) {
          EventBus.$emit('ResetTicketCheckSetTimeout', payload.data);
        }
        break;
      }
      // gateway
      case 'User.AuthorizationChanged':
        try {
          if (getters.isTerminal) {
            dispatch('setTerminalUser', payload.data);
          } else {
            if (!payload.data.auth) {
              dispatch('user/updateUser', {
                auth: null,
                profile: null,
              });
              dispatch('user/setUserBalance', 0);
              return;
            }
            if (payload.data.auth.user?.settings?.oddType) {
              dispatch('setOddType', payload.data.auth.user.settings.oddType);
            }
            dispatch('setTpToken', payload.data.auth.tpToken || '');
            const user = payload.data.auth.token ? payload.data.auth.user : null;
            timezone = payload.data.auth.token && user.timezone
              ? user.timezone
              : state.config.timezone;
            if (timezone) {
              if (timezone !== state.timezone) {
                dispatch('setLoaderStatus', true);
                commit('SET_TIME_ZONE', timezone);
              }
            }
            if ((!getters.user.user.profile?.id && user) || (getters.user.user.profile?.id && !user)) {
              setTimeout(() => {
                EventBus.$emit('configureBoost');
              }, 500);
            }
            if (user) {
              dispatch('setUser', {
                id: user.id,
                removeUser:false,
              });
              dispatch('user/updateUser', {
                auth: {
                  token: payload.data.auth.token,
                  tpToken: payload.data.auth.tpToken,
                },
                profile: {
                  ...user,
                  logged: true,
                },
                isLoggedIn: true,
              });
              dispatch('user/setUserBalance', user.balance);
              dispatch('getLastTickets');
            } else {
              if (getters.user?.user?.profile?.id) {
                dispatch('setUser', {
                  id: getters.user.user.profile?.id,
                  removeUser: true,
                });
              }
              commit('gamesBetslip/SET_PLAYER_TICKETS', []);
              dispatch('user/updateUser', {
                auth: null,
                profile: null,
              });
              dispatch('user/setUserBalance', 0);
            }
          }
        } catch (error) {
          console.log('error', 'User.AuthorizationChanged error', error);
        }
        break;
      case 'User.BalanceChanged':
        dispatch('user/setUserBalance', payload.data.balance);
        dispatch('gamesBetslip/validateUserBalance');
        break;
      case 'Widget.SettingsChanged':
        if (state.oddType !== payload.data.oddType) {
          dispatch('setOddType', payload.data.oddType);
        }
        break;
      case 'Tickets.Checked':
        dispatch('getTicket', {
          id: payload.data.ticket.id,
        });
        break;
      case 'Tickets.FetchHistory':
        dispatch('getTicketsHistory', !getters.layoutTerminal ? payload.data.params : payload.data);
        break;
      case 'Betslip.Blocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'add',
        });
        break;
      case 'Betslip.Unblocked':
        dispatch('gamesBetslip/setBetslipBlockers', {
          blockers: payload.data.blockers,
          type: 'remove',
        });
        break;
        case 'Tickets.PayingSentSuccess':
          dispatch('gamesBetslip/clearBetslip');
          dispatch('gamesBetslip/setIsPayinInProgress', false);
        break;
      case 'Tickets.PayingFailed':
        dispatch('gamesBetslip/setIsPayinInProgress', false);
        break;
      case 'Tickets.PrintCopy': {
        if (payload.data.ticket.isCopy) {
          const { productName } = state.config;
          const { ticket } = payload.data;
          ticket.translation = getters.translations[ticket.translationKey || 'game_VirtualSoccer'];
          ticket.bets = formatPrintTicketBets(ticket.bets);
          dispatch('busServiceSendMessage', {
            action: 'Peripherals.Print',
            data: {
              layoutName: ticket.status.value.toLowerCase() === 'payedout'
                ? `ticket${productName}Payout` : `ticket${productName}`,
              data: ticket,
              productId: productName,

              additionalData: {
                taxes: state.config.taxes,
                clientPrintJobIdentifier: {
                  id: 'TicketCopy',
                  uuid: payload.data.responseData.requestUuid,
                },
              },
            },
          });
        }
        break;
      }
      // no default
    }
  },
  setIsBetslipOpen({ commit }, payload) {
    commit(types.SET_IS_BETSLIP_OPEN, payload);
  },
  setOddType: ({ commit }, payload) => {
    commit(types.SET_ODD_TYPE, payload);
  },
  setTpToken: ({ commit }, payload) => {
    commit(types.SET_TP_TOKEN, payload);
  },
  setUser: ({ state, dispatch, getters }, payload) => {
    if (!payload.removeUser && payload.id) {
      if (window.busService && !state.userPusherSubscribed) {
        if (getters.isUserSignedIn) {
          find(window.busService.adapters, ({ name }) => name === 'PusherAdapter')?.setAuthConfig(
            {
              TenantId: getters.config.tenantId,
              Authorization: `Bearer ${
                getters.sevenData?.auth?.token
                || getters.user.user.auth?.token
                || getters.userData?.token
              }`
            },
            process.env.VUE_APP_PRIVATE_PUSHER_APP_NAME
          );
          window.busService.addChannel('Player', payload.id, undefined, 'private-')
          dispatch('setUserPusherSubscribed', true)
        }
      }
    } else {
      if (payload.id && window.busService) {
        dispatch('setUserPusherSubscribed', false)
        window.busService.removeChannel('Player', payload.id, undefined, 'private-')
      }
    }
  },
  // eslint-disable-next-line no-unused-vars
  payinTicket: ({ state, dispatch }, payload) => {
    const url = `${process.env.VUE_APP_ADD_TICKET}${payload.ticketRequestData.metadata.requestUuid}`;
    return postData(url, payload.ticketRequestData, payload.headers);
  },
  checkTicket: ({ state, getters }, payload) => {
    try {
      const headers = createHeaders(
        {
          language: state.config.locale,
          uuid: state.config.tenantId,
        },
        {
          'Content-Type': 'application/json',
          'HTTP-X-SEVEN-CLUB-UUID': state.config.tenantId,
          'HTTP-X-NAB-DP': `${getters.isTerminal ? 'terminal' : 'web'}`,
          authorization: `Bearer ${state.user.user.profile.token}`,
        });
      const url = `${process.env.VUE_APP_CHECK_TICKET_AFTER_PAYIN}${payload}`;
      return getData(url, headers);
    } catch (e) {
      return e;
    }
  },
  checkTerminalRequest: async ({ state, dispatch }, payload) => {
    try {
      const headers = createHeaders(
        {
          language: state.config.locale,
          uuid: state.config.tenantId,
        },
        {
          'Content-Type': 'application/json',
          'HTTP-X-SEVEN-CLUB-UUID': state.config.tenantId,
          'HTTP-X-NAB-DP': `terminal`,
          'device-uuid': state.config.deviceId,
        });
      const url = `${process.env.VUE_APP_TERMINAL_CHECK_TICKET_AFTER_PAYIN}${payload}`;
      const response = await getData(url, headers)
      dispatch('callTerminalPayin', response.data);
      return response;
    } catch (e) {
      return e;
    }
  },
  getTicket: async ({ state, getters, commit }, payload) => {
    try {
      const { id, printPayout } = payload;
      const headers = createHeaders(
        {
          language: state.config.locale,
          uuid: state.config.tenantId,
        },
        {
          'Content-Type': 'application/json',
          'HTTP-X-SEVEN-CLUB-UUID': state.config.tenantId,
          'HTTP-X-NAB-DP': `${getters.isTerminal ? 'terminal' : 'web'}`,
        });
      const url = `${process.env.VUE_APP_GET_TICKET}${id}`;
      const { data } = await getData(url, headers);
      const { productName } = state.config;
      if (printPayout && getters.terminalUser.type === 'OPERATOR') {
        window.busService.sendMessage({
          action: 'Peripherals.Print',
          data: {
            layoutName: `ticket${productName}Payout`,
            data,
            productId: productName,

            additionalData: {
              clientPrintJobIdentifier: {
                id: 'TicketCopy',
                uuid: data.requestUuid,
              },
            },
          },
        }, true);
      }
      const ticket = ticketFormater(data);
      if (printPayout) {
        window.busService.sendMessage({
          action: 'Tickets.Update',
          data: {
            action: data.action,
            ticketData: data,
          },
        }, true);
      }
      commit('betslip/SET_TICKETS_HISTORY', [ticket]);
      EventBus.$emit('rawTicket', data);
    } catch (e) {
      return e;
    }
  },
  getLastTickets: async ({ state, commit }) => {
    try {
      commit('gamesBetslip/SET_IS_LAST_TICKETS_IN_PROGRESS', true);
      const headers = createHeaders({
        language: state.config.locale,
        uuid: state.config.tenantId,
      }, {
        'Content-Type': 'application/json',
        'X-Nsft-Seven-Tenant-Uuid': state.config.tenantId,
        authorization: `Bearer ${state.user.user.profile.token}`,
      });
      const url = `${process.env.VUE_APP_LAST_TICKETS}${state.user.user.profile.id}`;
      const { data } = await getData(url, headers);
      if (data.length) {
        const tickets = map(data, (ticket) => {
          return ticketFormater(ticket);
        });
        commit('gamesBetslip/SET_PLAYER_TICKETS', tickets);
      }

    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.data?.status === 401) {
        window.busService.sendMessage({
          action: 'User.Logout',
          data: {},
        });
        if (state.isAndroid && has(window, 'WebAppListener')) {
          // eslint-disable-next-line no-undef
          WebAppListener.sendMessage('User.Logout');
        }
      }
      return e;
    } finally {
      commit('gamesBetslip/SET_IS_LAST_TICKETS_IN_PROGRESS', false);
    }
  },
  getTicketsHistory: async ({ commit, state, getters }, payload) => {
    try {
      commit(types.TOGGLE_HISTORY_LOADER, true);
      const { tenantId, language } = getters;
      const start = payload.start || payload.timeFrom;
      const end = payload.end || payload.timeTo;
      const queryData = {
        page: payload.currentPage || 1,
        count: payload.count || 50000,
        startDate: replace(start, ' ','*'),
        endDate: replace(end, ' ','*'),
      };
      const headers = {
        'HTTP-X-NAB-TOKEN': tenantId,
        'HTTP-X-SEVEN-COMPANY-UUID': tenantId,
      };
      if (!getters.layoutTerminal) {
        queryData.playerUuid = state.user.user.profile.id || getters.sevenData.user.id;
        headers.authorization = `Bearer ${state.user.user.profile.token || getters.sevenData.user.token}`;
        headers.ticket_delivery_platform = 'Web';
      } else {
        queryData.deviceUuid = state.config.deviceId;
        headers.ticket_delivery_platform = 'Terminal';
        headers.authorization = `Bearer ${getters.terminalUser?.auth?.token}`;
      }
      const headerData = {
        language,
        uuid: tenantId,
      };
      const url = `${process.env.VUE_APP_TICKET_HISTORY}${createQueryParams(queryData)}`;
      const { data } = await getData(url, createHeaders(headerData, headers));
      if (data?.tickets.length) {
        const tickets = !getters.layoutTerminal ? map(data.tickets, (ticket) => {
          return ticketFormater(ticket);
        }) : data.tickets ;
        commit('betslip/SET_TICKETS_HISTORY', tickets);
        window.busService.sendMessage({
          action: 'Tickets.HistoryFetched',
          data: {
            params: {
              totalCount: data.totalCount,
            },
          },
        })
      } else {
        commit('betslip/SET_TICKETS_HISTORY', []);
      }

    } catch (e) {
      return e;
    } finally {
      commit(types.TOGGLE_HISTORY_LOADER, false);
    }
  },
  toggleTicketHistory({ commit }, payload) {
    commit(types.TOGGLE_TICKET_HISTORY, payload);
  },
  sendToPrint({ state, dispatch }, payload) {
    if (!payload.isCopy) {
      const { productName } = state.config;
      const ticket = {...payload};
      ticket.bets = formatPrintTicketBets(ticket.bets);
      const type = {
        payout: `ticket${productName}Payout`,
        payedout: `ticket${productName}Payout`,
        cancel: `ticket${productName}Cancel`,
        add: `ticket${productName}`,
      };
      const printData = {
        action: 'Peripherals.Print',
        data: {
          layoutName: type[ticket.action.toLowerCase()],
          data: ticket,
          productId: productName,
          additionalData: {
            taxes: state.config.taxes,
            clientPrintJobIdentifier: {
              id: type[ticket.action.toLowerCase()],
              uuid: ticket.requestUuid,
            },
          },
        },
      };
      if (ticket.action.toLowerCase() === 'add') {
        window.busService.clientAdapter.handler.emitAsync(printData)
          .then((res) => {
            if (res.ack) {
              dispatch('gamesBetslip/clearBetslip');
              dispatch('gamesBetslip/setIsPayinInProgress', false);
            }
          })
          .catch((e) => {
            window.busService.sendMessage({
              action: 'Dialog.Show',
              data: {
                message: e.message,
                type: 'error',
                delay: 3000,
              },
            }, true);
          });
      } else {
        window.busService.sendMessage(printData);
      }
    }
  },
  setTerminalUser({ commit }, payload) {
    commit(types.SET_TERMINAL_USER, payload);
  },
  callTerminalPayin({ getters, dispatch }, payload) {
    const actions = {
      ADD: 'Add',
      Add: 'Add',
      CANCEL: 'Cancel',
      PAYOUT: 'Payout',
    };
    let ticket = payload;
    ticket.translation = getters.translations[ticket.translationKey || 'game_VirtualSoccer'];
    ticket.action = actions[ticket.action];
    ticket.config = {
      color: '#1F9BDE',
      validateTicket: false,
      useTicketLocalStorage: false,
      doTicketStatusCheck: false,
      pendingTicketCheckInterval: 10000,
      pendingTicketRejectPeriod: 30000,
    };
    window.busService.sendMessage({
      action: 'Tickets.Update',
      data: {
        action: ticket.action,
        ticketData: ticket,
      },
    }, true);
    dispatch('sendToPrint', ticket);
  },
  async getOffer({commit, dispatch, state, getters}, socketMessage) {
    try {
      let payload = socketMessage;
      const headers = createHeaders({
        language: state.config.locale,
        uuid: state.config.tenantId,
      }, {
        'Content-Type': 'application/json',
        'x-nsft-productid': state.config.productId,
      });
      const url = `${process.env.VUE_APP_OFFER}`;
      const { data } = await getGameServiceData(url, headers);
      const offer = data.message;
      if (offer && offer.length) {
        const activeMatchIndex = findIndex(offer,
          (match) => match.eventIdToday === getters.activeEventId);
        const activeMatchIdValid = activeMatchIndex >= 0;
        if (!activeMatchIdValid) {
          dispatch('updateActiveEvent', null);
        } else {
          const vsSchedule = document.getElementById('vs-schedule');
          const children = vsSchedule.querySelectorAll('button.round-wrapper');
          setTimeout(() => {
            children[activeMatchIndex].click();
          }, 150);
        }
        commit(types.SET_OFFER, offer);
        if (!getters.layoutTerminal) {
          payload.data.offer = offer;
          sendMessageToVisualization(payload);
        }
        const offerEventsId = map(offer, (item) => item.eventId);
        forEach(getters['gamesBetslip/ticket'], (bet) => {
          if (indexOf(offerEventsId, bet.eventId) < 0) {
            dispatch('gamesBetslip/updateBet', {
              ...bet,
              locked: true,
              permanentlyLock: true,
            });
          }
        });
      }
      if (getters['user/isLoggedIn']) {
        dispatch('getLastTickets');
      }

    } catch (e) {
      if (e?.response?.status === 401 || e?.response?.data?.status === 401) {
        window.busService.sendMessage({
          action: 'User.Logout',
          data: {},
        });
        if (state.isAndroid && has(window, 'WebAppListener')) {
          // eslint-disable-next-line no-undef
          WebAppListener.sendMessage('User.Logout');
        }
      }
    }
  },
  setUserPusherSubscribed({ commit }, payload) {
    commit(types.USER_PUSHER_SUBSCRIBED, payload);
  },
};
