import { filter, replace, some } from 'lodash';

export default {
  queryParametars: (state) => state.queryParametars,
  screenSize: (state) => state.screenSize,
  isAudioOn: (state) => state.isAudioOn,
  isVisualizationReady: (state) => state.isVisualizationReady,
  config: (state) => state.config,
  currentEvent: (state) => state.currentEvent,
  isVideoPlayingAndAvailable: (state, { currentEvent, isVideoUnavailable }) =>
    currentEvent === 'showVideos' && !isVideoUnavailable,
  // isVideoUnavailable is set to true when we boot the application mid-match
  isVideoUnavailable: (state) => state.isVideoUnavailable,
  language: (state) => state.config.locale,
  tenantId: (state) => state.config.tenantId,
  translation: (state, getters) => (key, value = null, value2 = null) => {
    const translation = getters.translations[key] || key;
    let message = '';
    message = value !== null ? replace(translation, '{value}', value) : translation;
    message = value2 !== null ? replace(message, '{value2}', value2) : message;
    return message;
  },
  offer: (state) => state.offer,
  offerSpecification: (state) => state.config.state.offer,
  ui: (state, getters) => getters.config.ui,
  theme: (state, getters) => getters.ui.config.theme,
  activeEventId: (state) => state.activeEventId,
  gamesInfoActive: (state) => state.gamesInfoActive,
  activeMatch: (state) => {
    let activeMatch = null;
    if (state.activeEventId) {
      activeMatch = filter(state.offer,
        (offer) => offer.eventIdToday === state.activeEventId)[0];
    } else {
      activeMatch = state.offer[0];
    }
    return activeMatch;
  },
  isMinimizeVisible: (state) => state.isMinimizeVisible,
  isUserSignedIn: (state, getters) => some([getters.sevenData?.auth?.token, getters.user.user.auth?.token, getters.userData?.token]),
  isBetslipOpen: (state) => state.isBetslipOpen,
  infoModalActive: (state) => state.infoModalActive,
  oddType: (state) => state.oddType,
  isLoaderActive: (state) => state.loader,
  user: (state) => state.user,
  tpToken: (state) => state.tpToken,
  nextRoundProp: (getters) => (key) => getters.offer[0][key],
  isAndroid: (state) => state.isAndroid,
  activeTicketHistory: (state) => state.activeTicketHistory,
  activeHistoryLoader: (state) => state.activeHistoryLoader,
  boost: (state) => state.config.ui.config.boost,
  boostActive: (state) => state.config.ui?.config?.boost?.enabled,
  isTerminal: (state) => state.queryParametars.get('application') === 'terminal',
  nextMatchTime: (state, getters) => getters.nextRoundProp('timestamp'),
  betslipBlockers: (state) => state.betslipBlockers,
  terminalUser: (state) => state.terminalUser,
  showMinimizeButton: (state, getters) => getters.screenSize < 1280 && getters.layoutTerminal,
  isIosApp: (state) => state.isIosApp,
  removeCursor: (state, getters) => getters.layoutTerminal && !state.showCursor,
  scrollButtons: (state, getters) => getters.layoutTerminal
    && state.scrollButtons,
  internetConnection: (state) => state.internetConnection,
  isIntegration: (state) => state.isIntegration,
  sevenData: (state) => state.sevenData,
  rgsIntegration: (state) => state.rgsIntegration,
  layoutTerminal: (state) => state.queryParametars.get('application') === 'terminal'
    || state.queryParametars.get('layout') === 'terminal',
  userPusherSubscribed: (state) => state.userPusherSubscribed,
  userData: (state) => state.userData,
};
