<script setup>
import LastTicketsBetItem from '@nsftx/games-sdk-js/src/components/BetslipArea/LastTickets/LastTicketsBetItem';
import {defineProps, toRefs} from '@vue/compat';
import {computed, ref} from 'vue';
import {useStore} from 'vuex';
const props = defineProps({
  ticketsHistory: {
    type: Array,
    default: () => [],
  },
  detailsMode: {
    type: String,
    default: 'standalone',
  },
  sevenPlatform: {
    type: Boolean,
    default: () => false,
  },
});
const store = useStore();
const productName = computed(() => store.getters.translation('general_virtualSoccer'));
const activeTicket = ref('');
const {
  detailsMode,
  ticketsHistory,
} = toRefs(props);
const toggleActiveTicket = (ticketId) => {
  if (activeTicket.value === ticketId) return;
  activeTicket.value = ticketId;
};
const removeActiveTicket = () => {
  activeTicket.value = '';
};
</script>

<template>
  <div>
    <div
      v-for="ticket in ticketsHistory"
      :key="ticket.id"
      class="ticket-history-item"
      @click="toggleActiveTicket(ticket.id)"
    >
      <div
        v-if="activeTicket === ticket.id"
        class="click-ticket-header"
        @click.stop="removeActiveTicket()"
      />
      <LastTicketsBetItem
        :ticket="ticket"
        :show-footer="false"
        :received-is-last-tickets-data="false"
        :details-mode="detailsMode"
        :product-name-label="productName"
        :seven-platform="sevenPlatform"
        :accordion-header="false"
        active-accordion-type="single"
        :active-accordion="activeTicket"
        :is-close-button-allowed="false"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
.ticket-history-item {
  margin-bottom: 2px;
  position: relative;

  .click-ticket-header {
    cursor: pointer;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 60px;
  }

  :deep(.last-tickets-item) {
      height: 100%;
      .body {
        .bet-item {
          height: 100%;
        }
      }
    }
  }

.ticket-history-item:last-child {
  margin-bottom: 0;
}
</style>
